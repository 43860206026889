<template>
  <div>
    <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reportes por saldos"/>
    <b-form @submit="onSubmit" v-if="show">
      <x-form-group title="Período de consulta">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-datepicker
              id="form-fecha-inicial"
              v-model="fecha_inicial"
              class="mb-2"
              size="sm"
              placeholder="Fecha de inicio"
              required
            />
          </b-col>

          <b-col cols="12" md="6">
            <b-form-datepicker
              id="form-fecha-final"
              v-model="fecha_final"
              class="mb-2"
              size="sm"
              placeholder="Fecha final"
              required
            />
          </b-col>
        </b-row>
      </x-form-group>

      <x-form-group title="Rango de cuentas">
        <b-row>
          <b-col cols="12" md="6">
            <treeselect
              id="form-clave-inicial"
              v-model="clave_inicial"
              :options="getList"
              :placeholder="!isListAvailable ? 'Cargando información...' : 'Seleccione una clave de cuenta'"
              :disabled="!isListAvailable"
              disable-branch-nodes
              disable-fuzzy-matching
              clearable
              searchable
              search-nested>
            </treeselect>
          </b-col>

          <b-col cols="12" md="6">
            <treeselect
              id="form-clave-final"
              v-model="clave_final"
              :options="getList"
              :placeholder="!isListAvailable ? 'Cargando información...' : 'Seleccione una clave de cuenta (opcional)'"
              :disabled="!isListAvailable"
              disable-branch-nodes
              disable-fuzzy-matching
              clearable
              searchable
              search-nested>
            </treeselect>
          </b-col>
        </b-row>
      </x-form-group>

      <b-form-group>
        <div class="w-100 d-flex justify-content-between">
          <b-button type="submit" size="sm" :disabled="loadingClaves || loading" variant="primary">Consultar</b-button>
          <b-button :disabled="loadingClaves || loading" @click="onExport()" size="sm" variant="success">Exportar</b-button>
        </div>
      </b-form-group>
    </b-form>

    <div class="card card-header-actions">
      <div class="card-header">
        Reporte
      </div>
      <div class="card">
        <b-overlay :show="loading">
          <template #overlay>
            <loading />
          </template>

          <b-table
            :fields="fields"
            :items="getReporte"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(clave)="data">
              <div :class="data.item.nivel===1?'text-primary text-sm font-weight-bold':'text-primary text-sm'" v-b-tooltip.hover :title="data.value.nombre">{{ data.value }}</div>
            </template>
            <template #cell(poliza)="data">
              <div class="text-primary text-sm">{{ data.value }}</div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-sm">{{ data.value }}</div>
            </template>
            <template #cell(concepto)="data">
              <div v-if="data.item.nivel === 0" class="text-primary font-weight-bold float-right">{{ data.value }}</div>
              <div v-else :class="data.item.nivel===1?'text-primary text-sm font-weight-bold':'text-primary text-sm'">{{ data.value }}</div>
            </template>
            <template #cell(saldo_inicial)="data">
              <div v-if="data.value === ''"></div>
              <div v-else-if="data.item.nivel === 0 && data.value >= 0" class="text-primary font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 0 && data.value <  0" class="text-danger font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-primary text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-primary text-sm float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
            </template>
            <template #cell(cargo)="data">
              <div v-if="data.value === ''"></div>
              <div v-else-if="data.item.nivel === 0 && data.value >= 0" class="text-primary font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 0 && data.value <  0" class="text-danger font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-primary text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-primary text-sm float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
            </template>
            <template #cell(abono)="data">
              <div v-if="data.value === ''"></div>
              <div v-else-if="data.item.nivel === 0 && data.value >= 0" class="text-primary font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 0 && data.value <  0" class="text-danger font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-primary text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-primary text-sm float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
            </template>
            <template #cell(saldo)="data">
              <div v-if="data.value === ''"></div>
              <div v-else-if="data.item.nivel === 0 && data.value >= 0" class="text-primary font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 0 && data.value <  0" class="text-danger font-weight-bold float-right">{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value >= 0" class="text-primary text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 1 && data.value <  0" class="text-danger text-sm font-weight-bold float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value >= 0" class="text-primary text-sm float-right" >{{ $formatNumber(data.value) }}</div>
              <div v-else-if="data.item.nivel === 2 && data.value <  0" class="text-danger text-sm float-right" >{{ $formatNumber(data.value) }}</div>
            </template>
          </b-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'ReporteAuxiliarCuentas',

  components: {
    Treeselect
  },

  data () {
    return {
      show: true,

      reset: false,

      loading: false,

      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'poliza', label: 'Poliza' },
        { key: 'fecha', label: 'Fecha ' },
        { key: 'concepto', label: 'Concepto' },
        // Importes
        { key: 'saldo_inicial', label: 'Saldo Inicial' },
        { key: 'cargo', label: 'Cargo' },
        { key: 'abono', label: 'Abono' },
        { key: 'saldo', label: 'Saldo' }
      ]
    }
  },

  computed: {
    ...mapGetters('reporteAuxiliarCuentasModule', [
      'getReporte', 'getParams'
    ]),

    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ]),

    ...mapGetters('clavesCuentasModule', [
      'getOptions', 'getList', 'isListAvailable'
    ]),

    loadingClaves () { return !this.isListAvailable },

    // Parámetros
    params: {
      get () {
        return this.getParams
      }
    },

    periodo_fiscal_id: {
      get () {
        return this.getParams.periodo_fiscal_id
      },
      set (value) {
        this.setPeriodoFiscalId(value)
      }
    },
    fecha_inicial: {
      get () {
        return this.getParams.fecha_inicial
      },
      set (value) {
        this.setFechaInicial(value)
      }
    },
    fecha_final: {
      get () {
        return this.getParams.fecha_final
      },
      set (value) {
        this.setFechaFinal(value)
      }
    },

    clave_inicial: {
      get () {
        return this.getParams.clave_inicial
      },
      set (value) {
        this.setClaveInicial(value)
      }
    },

    clave_final: {
      get () {
        return this.getParams.clave_final
      },
      set (value) {
        this.setClaveFinal(value)
      }
    }

  },

  methods: {
    ...mapMutations('reporteAuxiliarCuentasModule', [
      'setPeriodoFiscalId', 'setFechaInicial', 'setFechaFinal', 'setClaveInicial', 'setClaveFinal'
    ]),

    loadCatalogo (periodo_id, isAvailable, dispatchRoute, title) {
      if (!isAvailable) {
        this.$store.dispatch(
          dispatchRoute,
          periodo_id
        ).then(result => {
          if (result.error) this.$notify(result, title)
        })
      }
    },

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      // Cargamos listado de claves
      this.loadCatalogo(periodo_fiscal_id, this.isListAvailable, 'clavesCuentasModule/getAll', 'Claves')

      this.periodo_fiscal_id = periodo_fiscal_id
    },

    async onSubmit (event) {
      event.preventDefault()

      // Verificamos parámetros de la consulta
      if ((!this.fecha_inicial) || (!this.fecha_final) || (!this.clave_inicial)) {
        this.$notify({ error: true, message: 'Debe especificar los parámetros de la consulta' }, 'Error')
        return
      }

      this.loading = true
      const { error, message } = await this.$store.dispatch('reporteAuxiliarCuentasModule/getReporteAuxiliar', this.params)
      this.loading = false

      if (error) return this.$notify({ error, message }, 'Error')
    },

    async onExport () {
      // Verificamos parámetros de la consulta
      if ((!this.fecha_inicial) || (!this.fecha_final) || (!this.clave_inicial)) {
        this.$notify({ error: true, message: 'Debe especificar los parámetros de la consulta' }, 'Error')
        return
      }

      this.loading = true
      const getval = await this.$store.dispatch('reporteAuxiliarCuentasModule/exportReporte', this.params)
      this.loading = false
      if (!getval.error) {
        this.$onDownload(
          getval,
          'reporte_auxiliar_cuentas.xlsx'
        )
      } else {
        this.$notify(getval, 'Exportación')
      }
    }
  }
}
</script>
<style>
.vue-treeselect__menu {
  color: black;
}
</style>
